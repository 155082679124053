import { useEffect, useState } from "react";

import styles from "./Blogs.module.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBlogByCategoryAndBlognameApi,
  getBlogByCategoryApi,
  getBlogCategoryApi,
  getRecentBlogsApi,
} from "../../apis";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faIdBadge,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { RecentBlogs } from "./RecentBlogs";
import { BlogCategories } from "./BlogCategories";
import BlogSubscriber from "./BlogSubscriber";

const BlogDetails = (props: any) => {
  const navigate = useNavigate();
  const { category_name, blog_name } = useParams();

  const [blog, setBlog] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let header = document.getElementById("site-header");
    if (header) {
      header?.classList?.add("header-black-bg");
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBlogByCategoryNameAndBlogName();
  }, [blog_name, category_name]);

  const getBlogByCategoryNameAndBlogName = async () => {
    try {
      if (blog_name && category_name) {
        setLoading(true)
        const result = await getBlogByCategoryAndBlognameApi(
          blog_name,
          category_name
        );
        setBlog(result);
      }
    } catch (error) {
        console.error(error)
    }finally{
      setLoading(false)
    }

  };

  return (
    <div className="container pt-5">
      <div className="row">
        <div className={`col-lg-8 ${styles.verticalDivider} mt-5 pt-5`}>
          {loading ? (
            <div className="row justify-content-center align-items-center" style={{minHeight: 400}}>
              <div className="spinner-grow" role="status"></div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <h2>{blog?.title}</h2>
                <span>
                  <FontAwesomeIcon icon={faTag} className="me-1" />{" "}
                  {blog?.blogCategory?.name || 'Uncategorized'}
                </span>
                <span className="ms-3">
                  <FontAwesomeIcon icon={faCalendar} className="me-1" />{" "}
                  {moment(blog?.createdAt)?.format("MMMM DD, YYYY")}
                </span>
              </div>
              <div className="col-12 mt-5">
                <img
                  className="w-100 rounded"
                  src={blog?.featureImage}
                  alt=""
                />
              </div>
              <div className="col-12 mt-5">
                {blog && (
                  <div className={`${styles.contentImgWidth}`}>
                    {parse(blog?.content)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-4 mt-5 pt-5 mb-5">
          <RecentBlogs />
          <BlogCategories />
          <BlogSubscriber />
        </div>
      </div>
    </div>
  );
};

export { BlogDetails };
