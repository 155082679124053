import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import { getRecentBlogsApi } from "../../apis";
import { Link } from "react-router-dom";

const RecentBlogs = () => {
  const [recentBlogs, setRecentBlogs] = useState<any>();

  const getRecentBlogs = async () => {
    const result = await getRecentBlogsApi();
    if (result) {
      setRecentBlogs(result);
    }
  };

  useEffect(() => {
    getRecentBlogs();
  }, []);
  return (
    <div className={`${styles.recentArticles} mb-4`}>
      <h2 className={`${styles.sidebarTitle}`}>
        <a href="javascript:void(0)">Recent Articles</a>
      </h2>
      <ul className="list-unstyled">
        {recentBlogs?.map((blog: any) => {
          const categoryName = blog?.blogCategory?.[0]?.name?.split(" ")?.join("-") || "uncategorized";
          let blogName = blog?.title?.split(" ")?.join("-");
          blogName = blogName.split("/")?.join("{backslash}")
          return (
            <li key={blog._id}>
              <Link to={`/blogs/${categoryName}/${blogName}`}>
                {blog.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { RecentBlogs };
